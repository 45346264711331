export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '6059825478978721',
  agentpwd : '6059@8721$',
  apikey : '9eae5f9c4da41def4a1896680a34b1ba',
  url : 'https://menemo.afrikpay.com',
  id : 'menemo.afrikpay.com',
  accepturl : 'https://menemo.afrikpay.com/payment/web/success',
  cancelurl : 'https://menemo.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};
